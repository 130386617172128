import React from "react";
import loadable from "@loadable/component";

import "../../styles/blocks/gallery-modal.scss";
const Image = loadable(() => import("./image.js"));
const Modal = loadable(() => import("../modal.js"));

const GalleryModal = (props) => {
  const {
    isOpen,
    setIsOpen,
    activeImage,
    setActiveImage,
    content,
  } = props; 
  
  const handleKeyboard = (event=false) => {
    if(event.keyCode == 35) { setActiveImage(content.length - 1); return; }
    if(event.keyCode == 36) { setActiveImage(0); return; }
    if(event.keyCode == 37) { setActiveImage(activeImage === 0 ? content.length-1 : activeImage - 1); return; }
    if(event.keyCode == 39) { setActiveImage(activeImage === content.length-1 ? 0 : activeImage + 1); return; }
  };

  return (
    <Modal
      className="gallery"
      isOpen={isOpen}
      setIsOpen={ setIsOpen }
      handleKeyboard={ handleKeyboard }  
    >
      <Image
        gatsbyImageOptions={{
          objectFit: "contain",
          layout: "fixed"
        }}
        attributes={ content[activeImage].attributes } 
        size="full" 
      />
      <div>
        <button 
          className="begin"
          title="Revenir au début"
          onClick={ () => setActiveImage(0) }
          aria-label="go to back"
        >↩️</button>
        <button 
          className="previous"
          title="Précédent"
          onClick={ () => setActiveImage(activeImage === 0 ? content.length-1 : activeImage - 1) }
          aria-label="go to previous"
        >⬅️</button>
        <button 
          className="next"
          title="Suivant"
          onClick={ () => setActiveImage(activeImage === content.length-1 ? 0 : activeImage + 1) }
          aria-label="go to next"
        >➡️</button>
        <button 
          className="end"
          title="Aller à la fin"
          onClick={ () => setActiveImage(content.length - 1) }
          aria-label="go to end"
        >↪️</button>
      </div>
      <div className="gallery-slider">
        <div className="translate" style={{"--index": activeImage}}>
          {content.map((image,i) => {
            return( 
              <div 
                key={ i }  
                className={ (i == activeImage) ? "active" : "" }
                title=""
                onClick={ () => setActiveImage(i) }
              >
                <Image 
                  attributes={ image.attributes } 
                  size="thumb"
                />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default GalleryModal;  
